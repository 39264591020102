.slider {
    position: relative;
}

.slider .buttons {
    z-index: 1;
    right: 31%;
    top: 251px;
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50px;
    transform: translateX(50%);
    background-color: #f7941d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider .buttons .previous,
.slider .buttons .next {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.ion-ios-play{
    color: #ffff;
    font-size: 20px;
}


.slider .slide .testimonial {
    padding: 32px 51px;
    padding-left: 100px;
    background-size: 60px;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(https://alcs-slider.netlify.app/images/pattern-quotes.svg);
}

.slider .slide .testimonial blockquote {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 36px;
}

.slider .slide .testimonial .author {
    font-size: 15px;
    font-weight: 700;
}

.slider .slide .testimonial .author span {
    display: block;
    color: hsl(240, 18%, 77%);
    font-weight: 500;
}

.slider .slide .slider-img {
    width: 100%;
    padding: 30px;
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(https://alcs-slider.netlify.app/images/pattern-bg.svg);
}

.slider .slide .slider-img img {
    width: 240px;
    display: block;
    border-radius: 10px;
    box-shadow: 0px 16px 40px 0px rgba(135, 105, 210, 0.4);
}

.slider .slide .active {
    opacity: 1;
    transform: translateX(0) scale(1);
    transition-delay: 0.4s;
}
