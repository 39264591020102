@import "0-base/variables";

.hr {
  border-top: 3px solid black;
  margin-top: -5px;
}

.video-cta-content__title {
  font-size: 23px !important;
}

.see-more {
  display: none;
}

.project-img {
  width: 370px;
  height: 300px;
}
.footer--logo {
  height: 90px !important;
}

.project-img {
  height: 350px !important;
}

// .header-info-wrapper {
//   flex-direction: row-reverse;
//   padding-bottom: -5px;
//   padding-right: 10px;
// }

.rounded {
  border-radius: 5px !important;
}

.h-5 {
  height: 65px;
}

.bg-grey {
  background-color: rgb(239, 233, 233);
  &:hover {
    background-color: $theme-color--orange2;
  }
}

.btn-download {
  background-color: #ED9421;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 15px;
}

.circle {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 50%;
}

.social {
  display: flex;
  justify-content: flex-end;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9 !important;
}

.spinner-grow {
  height: 3.5vw;
  width: 3.5vw;
  margin-bottom: 20px;
}

.input-search::placeholder {
  color: rgb(243, 238, 238);
  opacity: 1;
}

.report-details  ul li {
  color: #0d0101 !important;
  border-bottom: 1px solid rgba(18, 1, 1, 0.181) !important;
}

.report-details {
  background-color: #babac65a !important;
  padding: 30px 35px;
}


.contactDir  ul li {
  color: #0d0101 !important;
  // border-bottom: 1px solid rgba(18, 1, 1, 0.181) !important;
}

.contactDir {
  background-color: #babac65a !important;
  padding: 30px 35px;
}



.shadow{
  box-shadow: 0px 0rem 1rem 4px rgba(0, 0, 0, 0.175) !important;
}

.disabled:hover{
background-color: rgb(143, 137, 137) !important;
cursor:not-allowed;
}

.btn-archive {
  background-color: $theme-color--default;
  margin-left: -12px;
  text-align: center;
  &:hover{
    background-color: $theme-color--orange2 !important;
  }
}

.btn--download{
    justify-content: center;
  &:hover{
    background-color: $theme-color--orange2 !important;
    color: honeydew;
  }
}

.grid--item{
  padding:2% 25% !important;
}